body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "REM", sans-serif;
  font-optical-sizing: auto;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: black;
  }
  &::-webkit-scrollbar-track{
    background: rgb(207, 207, 207);
  }
}
* {
  box-sizing: border-box;
}

@media print {
  @page {
      size: auto;
      margin: 0;
  }

  /* Hide content when printing to PDF */
  body::after {
      content: "Printing to PDF is disabled.";
      font-size: 20px;
      font-weight: bold;
      color: red;
      display: block;
  }
}
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

// .rem-<uniquifier> {
//   font-family: "REM", sans-serif;
//   font-optical-sizing: auto;
//   font-weight: <weight>;
//   font-style: normal;
// }